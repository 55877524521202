import { Container, Box, Typography, styled } from '@material-ui/core'
import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useAuth0 } from '@auth0/auth0-react'

import PageHeader from '../page-header'
import Spinner from '../loading/spinner'
import { useCart } from '../../hooks/useCart'
import { CartType } from '../../services/api/endpoints/carts'
import {
  Participant,
  useLazyGetParticipantsQuery,
} from '../../services/api/endpoints/participants'

const iconStyles = {
  fill: '#008174',
  fontSize: '35px',
}
const StyledTypographySubtitle = styled(Typography)`
  color: #3c3835;
  font-size: 18px;
  margin-top: -20px;
`

const StyledContainer = styled(Container)`
  margin-top: 40px;
`

const StyledBox = styled(Box)`
  margin-bottom: 40px;
`
const ParticipantBox = styled(Box)`
  display: block;
  width: 100%;
  padding: 20px 25px;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 6px;
  box-shadow: 0px 5px 10px 0px #eee;
  text-decoration: none;
  font-size: 20px;
`

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  color: #2e3a59;
  float: right;
`
const StyledPersonAddAltIcon = styled(PersonAddAltIcon)`
  color: #2e3a59;
  float: right;
`

const StyledParticipantItem = styled('span')`
  margin-top: 0.3rem;
  margin-left: 1rem;
  color: #3c3835;
`
const StyledBoxItem = styled(Box)`
  display: flex;
  align-items: center;
`

const CoachParticipantList = () => {
  const cartType: CartType = 'coach'

  const { getAccessTokenSilently } = useAuth0()
  const [trigger, { data: participants, isLoading }] =
    useLazyGetParticipantsQuery()

  const { setParticipantDetails, setParticipant } = useCart({
    type: cartType,
  })

  useEffect(() => {
    fetchParticipants()
  }, [])

  const fetchParticipants = async () => {
    const token = await getAccessTokenSilently()
    trigger(token)
  }

  const handleClick = async (participant: any) => {
    if (!participant) {
      navigate('/register/coach/details')
    } else {
      await setParticipantDetails({
        participantDetails: {
          firstName: participant.firstName,
          lastName: participant.lastName,
          dateOfBirth: participant.dateOfBirth,
          gender: participant.gender,
          email: participant.contact.email,
          mobile: participant.contact.phone,
          address: participant.address,
          emergencyContact: participant.emergencyContact.name,
          emergencyContactNumber: participant.emergencyContact.phone,
        },
        type: cartType,
      }).unwrap()

      await setParticipant({
        participantId: participant._id,
        type: cartType,
      }).unwrap()

      navigate('/register/coach/details')
    }
  }

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      ) : (
        <StyledContainer>
          <StyledBox>
            <PageHeader title="Choose profile" />
            <StyledTypographySubtitle>
              Who do you want to register
            </StyledTypographySubtitle>
          </StyledBox>
          <Box sx={{ paddingBottom: '0.5rem' }}>
            {participants &&
              participants.map((participant: Participant) => (
                <ParticipantBox
                  key={participant._id}
                  onClick={() => handleClick(participant)}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <StyledBoxItem>
                      <PersonOutlineIcon style={iconStyles} />
                      <StyledParticipantItem>
                        {participant.firstName} {participant.lastName}
                      </StyledParticipantItem>
                    </StyledBoxItem>
                    <StyledChevronRightIcon />
                  </Box>
                </ParticipantBox>
              ))}
            <ParticipantBox onClick={() => handleClick(false)}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <StyledBoxItem>
                  <StyledPersonAddAltIcon style={iconStyles} />
                  <StyledParticipantItem>NEW PARTICIPANT</StyledParticipantItem>
                </StyledBoxItem>
                <StyledChevronRightIcon />
              </Box>
            </ParticipantBox>
          </Box>
        </StyledContainer>
      )}
    </>
  )
}

export default CoachParticipantList
